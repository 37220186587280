<template>
  <div>
    <UButton 
      class="-mx-2.5"
      icon="i-heroicons-magnifying-glass-plus-20-solid"
      :label="showMoreLabel || ('toon meer ' + title.toLowerCase())"
      color="gray"
      variant="ghost"
      @click="open = true"
      />

      <UModal 
        v-model="open"
        :fullscreen="!grid.sm"
        :ui="{
          width: 'sm:max-w-[75vw]',
          overlay: {
            background: 'dark:bg-gray-950/75'
          }
        }"
      >
        <IconCard
          :icon="icon" 
          :color="color"
          :ui="{ 
            base: 'sm:max-h-[90dvh] h-full',
            ring: '', 
            rounded: 'rounded-none sm:rounded-lg',
            divide: 'divide-y divide-gray-100 dark:divide-gray-700',
            shadow: 'shadow-none',
            body: {
              base: 'overflow-y-scroll',
              padding: 'p-0 sm:p-0'
            }
          }"
        >
          <template #header>
            <div class="flex items-start gap-2 justify-between w-full">
              <h4 class="mb-0 mt-1 sm:mt-1.5 text-gray-600 dark:text-gray-400">{{title}}</h4>
              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="lg" class="shrink-0" @click="open = false" />
            </div>
            
          </template>

          <slot />

          <template #footer>
            <div class="w-full flex flex-row justify-end gap-3">
              <UButton variant="soft" color="gray" @click="open = false">Sluit</UButton>
            </div>
          </template>
        </IconCard>
      </UModal>
  </div>
</template>

<script lang="ts" setup>
import type { ButtonColor } from '#ui/types'

const props = withDefaults(defineProps<{
  modelValue: boolean,
  title: string
  icon?: string
  showMoreLabel?: string
  color?: ButtonColor
}>(), { color: 'primary'})


const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()


const open = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})

const { grid } = useResponsive()
</script> 

<style>

</style>